import React from 'react';
import Button from '@paprika/button';
import Panel from '@paprika/panel';
import Popover from '@paprika/popover';
import Toast from '@paprika/toast';
import Link from '@paprika/link';
import { useI18n } from '@paprika/l10n';
import tokens from '@paprika/tokens';
import FilesIcon from '@acl-services/wasabicons/lib/FileText';
import DownloadIcon from '@acl-services/wasabicons/lib/Download';
import File from 'types/File';

import './FilesPanel.scss';

type FilesPanelProps = {
  files: File[];
  isDownloadDisabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDownload: (fileName: string) => void;
};
export default function FilesPanel(props: FilesPanelProps): JSX.Element {
  const { files, isOpen, isDownloadDisabled, onClose, onDownload } = props;
  const I18n = useI18n();

  const getScriptNodeDetailsContainer = () => document.querySelector('.node-details__content-container');
  const panelOffsetTop = 175;
  const panelWidth = '420px';

  const getFileNameWithTooltip = (file: File) => {
    return (
      <Popover
        isEager
        isDark
        zIndex={9}
        className="files-panel__file-name-tooltip"
        edge={Popover.types.align.LEFT}
        offset={4}
      >
        <Popover.Trigger className="files-panel__file-name-tooltip-trigger">
          <div className="files-panel__file-name">{file.name}</div>
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card className="files-panel__file-name-tooltip-content">{file.name}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
    );
  };

  const getFileDownloadButton = (file: File) => {
    return (
      <Popover isEager isDark zIndex={9} edge={Popover.types.align.RIGHT} offset={4}>
        <Popover.Trigger>
          <Button.Icon
            className="files-panel__download-button"
            data-testid="files-panel__download-button"
            kind="minor"
            isPending={file.isDownloadPending}
            isDisabled={isDownloadDisabled}
            onClick={() => onDownload(file.name)}
          >
            <DownloadIcon color={tokens.color.blackLighten20} />
          </Button.Icon>
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card className="files-panel__download-file-tooltip-content">
            <div
              dangerouslySetInnerHTML={{ __html: I18n.t('files_panel.download.tooltip', { fileName: file.name }) }}
            ></div>
          </Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
    );
  };

  return (
    <Panel
      className="files-panel"
      isOpen={isOpen}
      onClose={onClose}
      pushContent={true}
      width={panelWidth}
      offset={{ top: panelOffsetTop }}
      getPushContentElement={getScriptNodeDetailsContainer}
    >
      <Panel.Header kind="default" className="files-panel__heading">
        <FilesIcon />
        {I18n.t('files_panel.heading')}
      </Panel.Header>
      <Panel.Content>
        <Toast>
          <div dangerouslySetInnerHTML={{ __html: I18n.t('files_panel.info_toast.message') }}></div>
          <Link isExternalLink href={I18n.t('files_panel.info_toast.learn_more.url')}>
            {I18n.t('files_panel.info_toast.learn_more.label')}
          </Link>
        </Toast>
        <ul className="files-panel__files-list" data-testid="files-panel__files-list">
          {files.map((file) => (
            <li className="files-panel__file" key={file.name} data-testid="files-panel__file">
              <div className="files-panel__file-icon">
                <FilesIcon />
              </div>
              <div className="files-panel__file-name-wrapper">{getFileNameWithTooltip(file)}</div>
              <div className="files-panel__file-download">{getFileDownloadButton(file)}</div>
            </li>
          ))}
        </ul>
      </Panel.Content>
      <Panel.Footer isSticky>
        <Button className="files-panel__close-button" kind="minor" onClick={onClose}>
          {I18n.t('files_panel.close_button')}
        </Button>
      </Panel.Footer>
    </Panel>
  );
}
