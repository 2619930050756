// TODO: separate production and staging flippers when we have a staging environment
const flippers = {
  flowDiagram: false,
  containerFiles: false,
};

const getFlippersWithUrlFlippers = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const flipperParams = urlParams.get('flippers')?.split(',') || [];

  const urlFlippers = flipperParams.reduce((acc, flipper) => {
    acc[flipper] = true;
    return acc;
  }, {});

  return { ...flippers, ...urlFlippers };
};

const flippersWithUrlFlippers = getFlippersWithUrlFlippers();

export default flippersWithUrlFlippers;
